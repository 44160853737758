import { ToastProvider } from 'react-toast-notifications';

import { AxiosInterceptor } from 'components/AxiosInterceptor';
import { Loader } from 'components/Loader';
import Router from 'components/Router';
import { AuthProvider } from 'contexts/AuthContext';
import { CustomerProvider } from 'contexts/CustomerContext';
import { LoaderStateProvider } from 'contexts/LoaderContext';

import 'pages/App.css';
import { SearchContextProvider } from '../contexts/SearchContext';

function App() {
  return (
    <ToastProvider>
      <AuthProvider>
        <AxiosInterceptor>
          <CustomerProvider>
            <LoaderStateProvider>
              <SearchContextProvider>
                <Router />
                <Loader />
              </SearchContextProvider>
            </LoaderStateProvider>
          </CustomerProvider>
        </AxiosInterceptor>
      </AuthProvider>
    </ToastProvider>
  );
}

export default App;
